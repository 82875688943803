<template>
  <div>
    <a-modal
      width="70em"
      v-model="visibleModalManagePending"
      class="mod"
      :title="
        status.isEdit
          ? 'Form Edit Pending'
          : status.isDetail
          ? 'Detail Student Candidate'
          : 'Form Create Pending'
      "
      @cancel="toggleModalManagePending"
      centered
    >
      <template v-if="status.isDetail">
        <div
          v-if="detailLoading"
          style="
            display: flex;
            justify-content: center;
            height: 30vh;
            align-items: center;
          "
        >
          <a-spin>
            <a-icon
              slot="indicator"
              type="loading"
              style="font-size: 5em; color: var(--kit-color-primary)"
              spin
            />
          </a-spin>
        </div>
        <div v-else>
          <detail-student-list
            :data="detailData"
            isEditable
            @handle-change="handleChange"
          />
        </div>
      </template>
      <!-- <form-pending :dataPending="newPending" @handle-change="handleChange" /> -->
      <template slot="footer">
        <template v-if="status.isDetail">
          <a-button
            @click="toggleModalManagePending"
            type="default"
            size="large"
            key="back"
            >Close</a-button
          >
          <a-button
            @click="handleUpdateData"
            :loading="loadingActionModalManagePending"
            type="primary"
            size="large"
            key="submit"
            >Update Now</a-button
          >
          <!-- <a-button
            @click="handlePendingAction('reject', detailData, true)"
            type="danger"
            size="large"
            key="back"
            >Reject</a-button
          >
          <a-button
            @click="handlePendingAction('approve', detailData, true)"
            type="primary"
            size="large"
            key="submit"
            >Approve</a-button
          > -->
        </template>
        <template v-else>
          <a-button size="large" key="back" @click="toggleModalManagePending">
            Cancel
          </a-button>
          <a-button
            size="large"
            key="submit"
            type="primary"
            :loading="loadingActionModalManagePending"
            @click="handleOkModalManagePending"
          >
            {{ status.isEdit ? "Edit" : "Create" }} Now
          </a-button>
        </template>
      </template>
    </a-modal>
    <a-modal
      :zIndex="2000"
      :title="titlePendingAction"
      :visible="visiblePendingAction"
      :confirm-loading="confirmLoadingPendingAction"
      :destroyOnClose="true"
      width="50em"
      :okText="
        status.isApprove ? 'Approve Now' : status.isReject ? 'Reject Now' : ''
      "
      :okType="status.isApprove ? 'primary' : status.isReject ? 'danger' : ''"
      :ok-button-props="{
        props: {
          disabled: status.isApprove
            ? false
            : status.isReject
            ? !newDataPendingAction.keterangan_ppdb
            : true,
        },
      }"
      @ok="handleOkPendingAction"
      @cancel="handleTogglePendingAction"
    >
      <template v-if="status.isApprove">
        <div>
          <h3>Are you sure want to approve {{ dataPendingAction.nama }}?</h3>
          <p class="mt-2">
            *This will make this student get notification of student's new
            account information <br />
            and this student status will become active at school
          </p>
          <!-- <hr /> -->
          <!-- <a-form-item label="NIS">
            <a-input
              v-model="newDataPendingAction.nis"
              class="w-100"
              size="large"
            />
          </a-form-item> -->
          <!-- <a-form-item label="Reason Approved">
            <a-input
              v-model="newDataPendingAction.keterangan_ppdb"
              class="w-100"
              size="large"
            />
          </a-form-item> -->
        </div>
      </template>
      <template v-if="status.isReject">
        <div>
          <h3>Are you sure want to reject {{ dataPendingAction.nama }}?</h3>
          <p class="mt-2">Please fill the reason why rejected</p>
          <hr />
          <a-form-item label="Reason Rejected">
            <a-input
              v-model="newDataPendingAction.keterangan_ppdb"
              class="w-100"
              size="large"
            />
          </a-form-item></div
      ></template>
    </a-modal>
    <!-- <div class="d-flex align-items-center mb-3">
      <div
        class="
          w-50
          border border-warning
          rounded
          px-3
          pb-3
          mr-3
          d-flex
          align-items-center
        "
      >
        <a-icon class="mt-3" :style="{ fontSize: '42px' }" type="container" />
        <div class="ml-4">
          <h1 class="">123</h1>
          <p class="text-uppercase">Total Pending Student</p>
        </div>
      </div>
      <div
        class="
          w-50
          border border-warning
          rounded
          px-3
          pb-3
          mr-3
          d-flex
          align-items-center
        "
      >
        <a-icon
          class="mt-3"
          :style="{ fontSize: '42px' }"
          type="clock-circle"
        />
        <div class="ml-4">
          <h1 class="">123</h1>
          <p class="text-uppercase">Today Pending Student</p>
        </div>
      </div>
    </div> -->

    <div class="d-flex align-items center">
      <a-input
        class="w-25"
        placeholder="Search Student"
        size="large"
        @change="handleSearch"
      />
      <a-button @click.prevent="exportExcel" class="ml-auto" :disabled="loadingExport" size="large" icon="database" type="primary">
        {{ loadingExport ? 'Exporting...' : 'Export List' }}
      </a-button>
      <!-- <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManagePending('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Pending</a-button
        >
      </div> -->
    </div>
    <div class="mt-4">
      <a-table
        :columns="pendingColumns"
        :data-source="pendingDataTable"
        :pagination="pendingPagination"
        :loading="pendingLoadingTable"
        @change="handleTableChange"
        bordered
      >
        <div slot="nullStr" slot-scope="text">{{text || '-'}}</div>
        <div slot="action" slot-scope="value, record">
          <div>
            <a-button
              @click.prevent="toggleModalManagePending('detail', record)"
              class="btn btn-sm btn-outline-primary rounded w-100"
              size="default"
            >
              <a-icon type="container" />Detail
            </a-button>
          </div>
          <div class="d-flex w-100 my-1">
            <a-button
              @click.prevent="handlePendingAction('approve', record)"
              style="width: 100%;"
              class="btn btn-sm btn-outline-success rounded mr-1"
              size="default"
            >
              <a-icon type="check" />Approve
            </a-button>
            <a-button
              @click.prevent="handlePendingAction('reject', record)"
              class="btn btn-sm btn-outline-danger rounded w-100"
              size="default"
            >
              <a-icon type="close" />
            </a-button>

          </div>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// const formPending = () => import('@/components/app/Admin/Master/Form/Pending')
import { exportDataPpdbMurid } from '@/helpers/ledgerExcel.js'
const detailStudentList = () => import('@/components/app/shared/DetailStudentList')

const pendingColumns = [
  {
    title: 'Student Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    sorter: true,
    width: 200,
  },
  {
    title: 'NIS',
    dataIndex: 'nis',
    key: 'nis',
    scopedSlots: { customRender: 'nullStr' },
    // sorter: true,
    align: 'center',
    width: 120,
  },
  {
    title: 'Submission Date',
    dataIndex: 'tanggal_pengisian_dapodik',
    key: 'tanggal_pengisian_dapodik',
    // scopedSlots: { customRender: 'tanggal_pengisian_dapodik' },
    align: 'center',
    sorter: true,
    width: 125,
  },
  {
    title: "Student's Email",
    dataIndex: 'email',
    key: 'email',
    // scopedSlots: { customRender: 'tanggal_pengisian_dapodik' },
    width: 150,
    scopedSlots: { customRender: 'nullStr' },
    align: 'center',
    sorter: true,
  },
  {
    title: "Parent's Email",
    dataIndex: 'email_perwakilan_orangtua',
    key: 'email_perwakilan_orangtua',
    width: 150,
    // scopedSlots: { customRender: 'tanggal_pengisian_dapodik' },
    scopedSlots: { customRender: 'nullStr' },
    align: 'center',
    sorter: true,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 150,
  },
]
export default {
  name: 'Pending',
  components: {
    // formPending,
    detailStudentList,
  },
  data() {
    return {
      pendingColumns,
      visibleModalManagePending: false,
      loadingActionModalManagePending: false,
      loadingDeletePending: false,
      loadingApprove: false,
      pendingLoadingTable: false,
      detailLoading: false,
      pendingDataTable: [],
      pendingPagination: {},
      detailData: {},
      status: {
        isEdit: false,
        isCreate: false,
        isDetail: false,
        isApprove: false,
        isReject: false,
      },
      newPending: {
        nama: null,
      },
      idEdit: null,
      newDataPendingAction: {
        // nis: null,
        keterangan_ppdb: null,
      },
      titlePendingAction: '',
      visiblePendingAction: false,
      confirmLoadingPendingAction: false,
      dataPendingAction: null,
      search: '',
      sortBy: '',
      order: '',
      loadingExport: false,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column, innerColumn } = payload
      if (innerColumn) {
        this.detailData[column][innerColumn] = value
      } else {
        this.detailData[column] = value
      }
      console.log('parent', payload)
      console.log('detailData', this.detailData)
    },
    toggleModalManagePending(status, data) {
      this.visibleModalManagePending = !this.visibleModalManagePending
      if (!this.visibleModalManagePending) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
            isDetail: false,
          }
          this.newPending = {
            nama: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : status === 'detail' ? 'isDetail' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPending = {
            nama: data.nama,
          }
        } else if (status === 'detail') {
          this.detailLoading = true
          this.fetchDetailStudent(data.key)
        }
      }
    },
    handleUpdateData() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to update this student data? this changes will affected to the system</div>
        ),
        onOk: () => {
          this.loadingActionModalManagePending = true
          this.$store.dispatch('master/PUT_STUDENT_PPDB', this.detailData)
            .then(() => {
              this.$notification.success({
                message: 'Success',
                description: 'Student data has been updated',
              })
            })
            .catch(err => {
              console.log(err)
              this.$notification.error({
                message: 'Failed',
                description: 'Process of updating student data has failed. Please try again later',
              })
            })
            .finally(() => {
              this.loadingActionModalManagePending = false
              this.toggleModalManagePending()
              this.fetchDataPending()
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes, Update now',
        cancelText: 'Cancel',
      })
    },
    handleOkModalManagePending() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this pending?' : 'Are you sure want to create new pending'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManagePending = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataPending: this.newPending,
            } : {
              dataPending: this.newPending,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_LEVEL`, payload)
              .then(isSuccess => {
                this.loadingActionModalManagePending = false
                this.toggleModalManagePending()
                this.fetchDataPending()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Pending has been updated' : 'Pending has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Pending has not been updated' : 'Pending has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleTogglePendingAction(innerModal) {
      this.visiblePendingAction = !this.visiblePendingAction

      if (!this.visiblePendingAction) {
        this.titlePendingAction = ''
        this.dataPendingAction = null
        this.status = {
          isDetail: innerModal,
          isApprove: false,
          isReject: false,
        }
        this.newDataPendingAction = {
          // nis: null,
          keterangan_ppdb: null,
        }
      }
    },
    handlePendingAction(type, data, innerModal = false) {
      this.titlePendingAction = `Student ${type === 'approve' ? 'Approval' : 'Rejection'} Confirmation`
      this.status[type === 'approve' ? 'isApprove' : type === 'reject' ? 'isReject' : ''] = true
      this.dataPendingAction = data
      this.handleTogglePendingAction(innerModal)
    },
    handleOkPendingAction() {
      const payload = {
        id: this.dataPendingAction.id,
        status_ppdb: this.status.isApprove ? 'accepted' : this.status.isReject ? 'rejected' : 'pending',
        ...this.newDataPendingAction,
      }
      this.$store.dispatch('ppdb/UPDATE_STUDENT_CANDIDATE_STATUS', { payload })
        .then(res => {
          this.$notification.success({
            message: 'Success',
            description: `${this.dataPendingAction.nama} has been ${this.status.isApprove ? 'Approved' : this.status.isReject ? 'Rejected' : ''}`,
          })
        })
        .catch(() => {
          this.$notification.error({
            message: 'Error',
            description: `${this.dataPendingAction.nama} has not been ${this.status.isApprove ? 'Approved' : this.status.isReject ? 'Rejected' : ''}, Please Try Again Later.`,
          })
        })
        .finally(() => {
          this.handleTogglePendingAction()
          this.visibleModalManagePending = false
          this.fetchDataPending()
        })
    },
    fetchDetailStudent(id) {
      this.$store.dispatch('ppdb/FETCH_DETAIL_STUDENT_CANDIDATE', { id })
        .then(res => {
          this.detailLoading = false
          this.detailData = res
        })
    },
    handleSearch(e) {
      this.search = e.target.value
      this.fetchDataPending()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pendingPagination }
      this.sortBy = sorter.columnKey
      pager.current = pagination.current
      this.pendingPagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchDataPending()
    },
    fetchDataPending() {
      this.pendingLoadingTable = true
      console.log(this.pendingPagination)
      this.$store.dispatch('ppdb/FETCH_STUDENT_CANDIDATE', { page: this.pendingPagination.current || 1, order: this.order, search: this.search, sortBy: this.sortBy, statusPPDB: 'pending' })
        .then(res => {
          this.pendingLoadingTable = false
          const pagination = { ...this.pendingPagination }
          pagination.total = res.total
          this.pendingPagination = pagination
          this.pendingDataTable = res.dataTable.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    async exportExcel() {
      try {
        this.loadingExport = true
        const allData = await this.$store.dispatch('ppdb/FETCH_ALL_DATA_BY_STATUS', { statusPpdb: 'pending' })
        const namaInstitusi = this.institusi?.nama?.toUpperCase() || ''
        const title = `DATA PESERTA DIDIK STATUS PENDING ${namaInstitusi}`
        await exportDataPpdbMurid(allData, { title })
        this.loadingExport = false
      } catch (err) {
        this.loadingExport = false
        console.log(err)
      }
    },
  },
  mounted() {
    this.fetchDataPending()
  },
  computed: {
    isValidateForm() {
      if (this.newPending.nama) {
        return false
      }
      return true
    },
    institusi() {
      return localStorage.institusi ? JSON.parse(localStorage.institusi) : {}
    },
  },
}
</script>

<style>
</style>
